<template>
  <page data-test="visits">
    <div slot="filters" class="w-100" data-test="search-ship">
      <div class="row">
        <input-search
          class="m-0 col-12 col-md-6 col-lg-4"
          v-model="searchModel.search"
          :placeholder="$t('view.list.search')"
        />
      </div>
    </div>
    <horizontal-scroll :current-index="currentScrollIndex">
      <page-column variant="6" gray scroll-container data-test="visits-list-section">
        <transition name="horizontal-scroll__delay-out">
          <visits-list-section @selected="selectVisit" v-if="!requestDetails" :filter-text="filterText" />
        </transition>
      </page-column>
      <page-column variant="6" class="m-0" scroll-container gray data-test="visits-details-section">
        <h5 class="text-center mt-5 py-4 text-secondary" v-if="!combinedVisitShipDetails">
          {{ $t('view.visit.select') }}
        </h5>
        <router-link
          class="mb-3 d-inline-block"
          v-if="combinedVisitShipDetails && requestDetails"
          :to="{ name: 'visits', params: { ucrn: combinedVisitShipDetails.ucrn } }"
        >
          <i class="icon icon-arrow-left pl-2 pr-1 text-decoration-none" />{{ $t('view.visit.back') }}
        </router-link>
        <page-row class="my-0">
          <page-column scroll-container class="my-0" :gray="!combinedVisitShipDetails">
            <visits-details-section @selected="selectVisitRequest" :selected-visit-details="combinedVisitShipDetails" />
          </page-column>
        </page-row>
      </page-column>
      <page-column variant="6" scroll-container>
        <transition name="horizontal-scroll__delay-out">
          <request-details-section :request-details="combinedRequestDetailsAndVessel" v-if="requestDetails" />
        </transition>
      </page-column>
    </horizontal-scroll>
  </page>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import HorizontalScroll from 'poronline-shared-vue-components/components/page/horizontal-scroll/horizontal-scroll';
import inputSearch from 'poronline-shared-vue-components/components/form/input-search';
import VisitsListSection from './visits-list-section';
import VisitsDetailsSection from './visit-details-section';
import RequestDetailsSection from '../requests/request-details-section';
import Page from '../../components/page/page';

export default {
  components: {
    Page,
    PageRow,
    PageColumn,
    inputSearch,
    HorizontalScroll,
    VisitsListSection,
    VisitsDetailsSection,
    RequestDetailsSection,
  },
  props: {
    ucrn: String,
    requestType: String,
    id: String,
  },
  data() {
    return {
      searchModel: {},
      filterText: null,
    };
  },
  computed: {
    ...mapState({
      ships: (state) => state.visits.ships,
      selectedVisitDetails: (state) => state.visits.selectedVisitDetails,
      requestDetails: (state) => state.form.requestDetails,
    }),
    ...mapGetters({
      visitsListByUcrn: 'VISITS_LIST_BY_UCRN',
    }),
    combinedVisitShipDetails() {
      return (
        this.selectedVisitDetails && {
          ...this.selectedVisitDetails,
          ship: this.selectedVisitShip,
        }
      );
    },
    selectedVisitShip() {
      return (
        this.selectedVisitDetails &&
        this.selectedVisitDetails.vessel.imo &&
        this.ships[this.selectedVisitDetails.vessel.imo]
      );
    },
    requestVessel() {
      if (!this.requestDetails) {
        return;
      }

      const vessel = this.visitsListByUcrn?.[this.requestDetails.request?.ucrn]?.vessel ?? null;

      return vessel ? { vessel } : {};
    },
    combinedRequestDetailsAndVessel() {
      return {
        ...this.requestDetails,
        ...this.requestVessel,
      };
    },
    searchText() {
      return this.searchModel.search;
    },
    currentScrollIndex() {
      return this.requestDetails ? 1 : 0;
    },
  },
  created() {
    this.$watch(
      (vm) => [vm.id, vm.requestType],
      async ([id, type]) => {
        if (!id || !type) {
          this.setRequestDetails(null);
          return;
        }

        try {
          await this.loadRequestDetails({ type, id: Number.parseInt(id, 10) });
        } catch (e) {
          this.$router.replace({ name: 'visits', params: { ucrn: this.ucrn } });
        }
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    ...mapMutations({
      setSelectedVisitDetails: 'SET_SELECTED_VISIT_DETAILS',
      setRequestDetails: 'SET_REQUEST_DETAILS',
    }),
    ...mapActions({
      loadVisitDetails: 'LOAD_VISIT_DETAILS',
      loadRequestDetails: 'LOAD_REQUEST_DETAILS',
    }),
    selectVisit(ucrn) {
      const { ucrn: routeUcrn } = this.$route.params;
      if (this.$route.name !== 'visits' || ucrn !== routeUcrn) {
        this.$router.push({ name: 'visits', params: { ucrn } });
      }
    },
    selectVisitRequest({ id, type, ucrn }) {
      const { ucrn: routeUcrn, id: routeId } = this.$route.params;

      if (this.$route.name !== 'visitRequest' || ucrn !== routeUcrn || id !== routeId) {
        this.$router.push({ name: 'visitRequest', params: { ucrn, id, requestType: type } });
      }
    },
  },
  watch: {
    ucrn: {
      immediate: true,
      handler: async function (newUcrn) {
        if (!newUcrn) {
          this.setSelectedVisitDetails(null);
          return;
        }
        if (!this.visitsListByUcrn[newUcrn]) {
          this.$router.replace({ name: 'visits', params: { ucrn: null } });
          return;
        }

        try {
          await this.loadVisitDetails(newUcrn);
        } catch (e) {
          this.$router.replace({ name: 'visits', params: { ucrn: null } });
        }
      },
    },
    searchText(searchValue) {
      if (searchValue && searchValue.length < 3) {
        return;
      }

      this.filterText = searchValue;
    },
  },
};
</script>
