<template>
  <div v-if="selectedVisitDetails">
    <div class="row no-gutters mb-5">
      <div class="col-8">
        <h3 class="mb-2">{{ selectedVisitDetails.vessel.name }}</h3>
        <table class="table table-borderless">
          <tr v-for="(infoItem, index) in shipInfo" :key="index">
            <td class="pr-4 py-0">{{ $t(infoItem.translationLabelKey) }}</td>
            <td class="py-0">{{ infoItem.value }}</td>
          </tr>
        </table>
      </div>
      <div class="col-4">
        <responsive-image
          :src="shipImage.imageUrl"
          :linkUrl="shipImage.linkUrl"
          :placeholder="shipImage.placeholder"
          :noImageAvailableText="$t('view.ship.noImage')"
        />
      </div>
    </div>
    <hr />
    <div>
      <div class="d-flex justify-content-between align-items-center mb-4" data-test="visits-overview">
        <h3 class="mb-0">{{ $t('view.visits.overview') }}</h3>
        <por-btn
          variant="primary"
          class="align-self-end"
          :to="{ name: 'new-request', query: { ucrn: selectedVisitDetails.ucrn } }"
          >{{ $t('view.request.new.button') }}
        </por-btn>
      </div>
      <div>
        <list-item
          v-for="(movement, index) in portcallMovements"
          :key="index"
          :disabled="true"
          class="mb-3 list-item__movements"
        >
          <div class="d-flex justify-content-between align-items-start">
            <div>
              <h6>{{ movement.fromBerth.berth.name }} - {{ movement.toBerth.berth.name }}</h6>
              <div>
                <span v-if="movement.fromBerth.etd && !movement.fromBerth.atd">
                  {{ $t('generic.abbr.etd') }} {{ $t('view.visit.movement.origin') }}
                  {{ formatDate(movement.fromBerth.etd, 'DATETIME_SHORT') }}</span
                >
                <span v-if="movement.fromBerth.atd">
                  {{ $t('generic.abbr.atd') }} {{ $t('view.visit.movement.origin') }}
                  {{ formatDate(movement.fromBerth.atd, 'DATETIME_SHORT') }}</span
                >

                <span
                  v-if="
                    (movement.toBerth.eta || movement.toBerth.ata) && (movement.fromBerth.atd || movement.fromBerth.etd)
                  "
                >
                  -
                </span>

                <span v-if="movement.toBerth.eta && !movement.toBerth.ata"
                  >{{ $t('generic.abbr.eta') }} {{ $t('view.visit.movement.destination') }}
                  {{ formatDate(movement.toBerth.eta, 'DATETIME_SHORT') }}</span
                >
                <span v-if="movement.toBerth.ata"
                  >{{ $t('generic.abbr.ata') }} {{ $t('view.visit.movement.destination') }}
                  {{ formatDate(movement.toBerth.ata, 'DATETIME_SHORT') }}</span
                >

                <span v-if="movement.warningTranslationKey">{{ $t(movement.warningTranslationKey) }}</span>
              </div>
            </div>
          </div>
        </list-item>
      </div>
      <hr class="mt-5" />
      <div class="pt-2" data-test="requests-overview">
        <h3 class="mb-4">{{ $t('view.requests.overview') }}</h3>
        <div v-if="openRequests.length === 0">{{ $t('view.requests.message') }}</div>
        <list-item
          v-for="visit in openRequests"
          :key="visit.request.id"
          :selected="
            $route.params.id === String(visit.request.id) &&
            $route.params.requestType === visit.request.type.toLowerCase()
          "
          @select="
            $emit('selected', {
              id: String(visit.request.id),
              type: visit.request.type,
              ucrn: selectedVisitDetails.ucrn,
            })
          "
          class="mb-3"
        >
          <div class="d-flex justify-content-between align-items-start" :data-test="[`request-${visit.ucrn}`]">
            <div>
              <h6>
                <span class="sr-only">{{ $t('view.request.title') }} </span> {{ visit.request.vessel.name }} ({{
                  $t('generic.abbr.imo')
                }}
                {{ visit.request.vessel.imo }}) - {{ typeLabel(visit.request.type) }}
              </h6>
              <span class="d-inline-block"
                >{{ visit.ucrn }} - {{ $t('view.request.creation.label') }}
                {{ formatDate(visit.request.creationTime, 'DATETIME_SHORT') }}</span
              >
            </div>
            <b-badge pill :variant="visit.request.evaluationStatus | evaluationStatusColour">
              {{ visit.request.evaluationStatus | evaluationStatusLabel }}
            </b-badge>
          </div>
        </list-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BBadge } from 'bootstrap-vue';
import map from 'lodash/map';
import find from 'lodash/find';
import PorBtn from 'poronline-shared-vue-components/components/por-btn';
import ListItem from 'poronline-shared-vue-components/components/list-item';
import ResponsiveImage from 'poronline-shared-vue-components/components/responsive-image';
import withEchoLabels from '../../components/form/with-echo-form-labels';
import shipPlaceHolderSvg from '../../assets/ship-icon-visit-placeholder.svg';
import { evaluationStatusLabel, evaluationStatusColour } from '../../filters/evaluation-status';

export default {
  name: 'VisitDetailsSection',
  mixins: [withEchoLabels],
  props: {
    selectedVisitDetails: { required: true },
  },
  components: { PorBtn, ListItem, ResponsiveImage, BBadge },
  computed: {
    ...mapGetters({
      requestsByUcrn: 'REQUESTS_BY_URCN',
    }),
    shipImage() {
      const imo = this.selectedVisitDetails.vessel.imo;

      return {
        placeholder: shipPlaceHolderSvg,
        linkUrl: `https://www.fleetmon.com/vessels/?s=${imo}`,
        imageUrl: `//www.fleetmon.com/capi/shipimage?s=thumb&imo=${imo}`,
      };
    },
    shipInfo() {
      return [
        {
          translationLabelKey: 'view.visit.ucrn',
          value: this.selectedVisitDetails.ucrn,
        },
        {
          translationLabelKey: 'generic.abbr.imo',
          value: this.selectedVisitDetails.vessel.imo,
        },
        {
          translationLabelKey: 'view.ship.lengthWidth.label',
          value: this.$t('view.ship.lengthWidth.value', {
            length: this.$n(this.selectedVisitDetails.ship.length.valueInCm / 100),
            width: this.$n(this.selectedVisitDetails.ship.beam.valueInCm / 100),
          }),
        },
        {
          translationLabelKey: 'view.ship.type',
          value: this.selectedVisitDetails.ship.category,
        },
      ];
    },
    portcallMovements() {
      const { movements, berthVisits } = this.selectedVisitDetails;
      return map(movements, (movement) => {
        const { idFromBerth, idToBerth } = movement;
        const fromBerth = idFromBerth ? find(berthVisits, ({ externalId }) => externalId === idFromBerth) : null;
        const toBerth = idToBerth ? find(berthVisits, ({ externalId }) => externalId === idToBerth) : null;
        return {
          fromBerth: fromBerth || { berth: { name: this.$t('view.visit.movement.inbound') } },
          toBerth: toBerth || { berth: { name: this.$t('view.visit.movement.outbound') } },
          warningTranslationKey: this.isMissingMovementTimeline(fromBerth, toBerth)
            ? 'view.visit.movement.message'
            : null,
        };
      });
    },
    openRequests() {
      return this.requestsByUcrn[this.selectedVisitDetails.ucrn] || [];
    },
  },
  filters: {
    evaluationStatusLabel,
    evaluationStatusColour,
  },
  methods: {
    isMissingMovementTimeline(fromBerth, toBerth) {
      return !(fromBerth?.atd || fromBerth?.etd) && !(toBerth?.ata || toBerth?.eta);
    },
  },
};
</script>

<style lang="scss" scoped>
// temporary: removing hover from list items until actions for movements are included
// (0, 2, 0) specificity over !important
.list-item.list-item__movements {
  &,
  &:hover {
    box-shadow: none;
    cursor: initial;
  }
}
</style>
