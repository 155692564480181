<template>
  <page-section gray>
    <div class="d-flex justify-content-between align-items-center mb-3 py-1">
      <h2 class="mb-0">{{ $t('view.visits.title') }}</h2>
      <div class="text-nowrap">
        <span>{{ $t('view.list.sort.title') }}</span>
        <link-selector
          class="position-relative d-inline-block py-1"
          v-model="sortOn"
          :options="sortOptions"
          data-test="sort"
        />
      </div>
    </div>
    <p v-if="!loading && sortedVists && sortedVists.length === 0">{{ $t('view.list.sort.message') }}</p>
    <list-item
      v-for="visit in sortedVists"
      :key="visit.ucrn"
      :selected="$route.params.ucrn === visit.ucrn"
      @select="$emit('selected', visit.ucrn)"
      class="mb-3"
    >
      <div class="d-flex justify-content-between align-items-start" :data-test="visit.ucrn">
        <div>
          <h6>
            {{ visit.ucrn }}<span v-if="visit.vessel"> - {{ visit.vessel.name }}</span> ({{ $t('generic.abbr.imo') }}
            {{ visit.vessel.imo }})
          </h6>
          <span v-if="visit.etaPilotStation" class="d-inline-block mr-4"
            >{{ $t('generic.abbr.eta') }} {{ $t('view.visit.movement.pilot') }}
            {{ formatDate(visit.etaPilotStation, 'DATETIME_SHORT') }}</span
          >
        </div>
      </div>
    </list-item>
    <loader v-if="loading" />
  </page-section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import kebabCase from 'lodash/kebabCase';
import words from 'lodash/words';
import every from 'lodash/every';
import filter from 'lodash/filter';
import { DateTime } from 'luxon';
import Loader from 'poronline-shared-vue-components/components/loader';
import ListItem from 'poronline-shared-vue-components/components/list-item';
import LinkSelector from 'poronline-shared-vue-components/components/link-selector';
import PageSection from '../../components/page/page-section';

export default {
  name: 'VisitsListsSection',
  props: {
    filterText: { type: String },
  },
  components: { PageSection, ListItem, LinkSelector, Loader },
  data() {
    const sortOptions = Object.freeze([
      {
        id: 'desc',
        value: [
          ['hasEtaPilotStation', 'dateTimeEtaPilotStation', 'vesselName'],
          ['asc', 'desc', 'asc'],
        ],
        text: this.$t('view.list.sort.options.eta.desc'),
      },
      {
        id: 'asc',
        value: [
          ['hasEtaPilotStation', 'dateTimeEtaPilotStation', 'vesselName'],
          ['asc', 'asc', 'asc'],
        ],
        text: this.$t('view.list.sort.options.eta.asc'),
      },
    ]);

    return {
      sortOptions,
      sortOn: sortOptions[0].value,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.visits.visitsListLoading,
      visits: (state) => state.visits.visitsList,
    }),
    ...mapGetters({
      requestsByUcrn: 'REQUESTS_BY_URCN',
    }),
    searchableSortableVisits() {
      return map(this.visits, (visit) => ({
        ...visit,
        hasEtaPilotStation: !visit.etaPilotStation,
        dateTimeEtaPilotStation: DateTime.fromISO(visit.etaPilotStation).valueOf(),
        vesselName: visit.vessel.name,
        searchableText: kebabCase([visit.ucrn, visit.vessel.name, visit.vessel.imo].join(' ')),
      }));
    },
    filterWords() {
      return this.filterText ? map(words(this.filterText), kebabCase) : null;
    },
    filteredVisits() {
      if (!this.filterWords) {
        return this.searchableSortableVisits;
      }

      return filter(this.searchableSortableVisits, (request) =>
        every(this.filterWords, (word) => request.searchableText.includes(word))
      );
    },
    sortedVists() {
      return orderBy(this.filteredVisits, ...this.sortOn);
    },
  },
};
</script>
